<template>
  <div>
    <div class="form" v-if="formFlag">
      <h4>{{ $t('deposit.cc.header') }}</h4>
      <slot></slot>
    </div>
    <div class="form_success" v-if="successFlag">
      <div class="form_success_content">
        <div class="show" v-if="hideUpload">
          <img v-if="icon_show_success" src="@/assets/images/dialog_true.png" alt />
          <img v-if="icon_show_fail" src="@/assets/images/dialog_false.png" alt />
          <p class="last">{{ resultMessage }}</p>
          <router-link :to="{ name: 'home' }" class="el-button purple_button" data-testid="bkToHm">
            {{ $t('common.button.bkToHm') }}
          </router-link>
        </div>
        <div class="upload" v-else>
          <el-form ref="paymentForm">
            <div class="info">
              <p>{{ $t('deposit.cc.cardUpload') }}</p>
            </div>
            <ul class="clearfix">
              <li class="fl">
                <vUpload :limit="1" v-on:updateFileInfo="updateFileInfo" data-testid="updateFileInfo"></vUpload>
              </li>
              <li class="fr">
                <img src="@/assets/images/withdraw/credit_img.jpg" alt />
              </li>
            </ul>
            <el-button class="el-button purple_button" @click="uploadImg()" data-testid="submit">
              {{ $t('common.button.submit') }}
            </el-button>
          </el-form>
        </div>
      </div>
    </div>
    <Result :fail="true" v-if="resultFlag">
      <div
        v-html="
          $t('deposit.cc.result.error', {
            email: GLOBAL_CONTACT_EMAIL
          })
        "
      ></div>
    </Result>
  </div>
</template>

<script>
import vUpload from '@/components/vUpload';
import Result from '@/components/Result';
import { apiUpload_card } from '@/resource';

export default {
  props: {
    formFlag: Boolean,
    hideUpload: Boolean,
    resultFlag: Boolean,
    successFlag: Boolean,
    icon_show_success: Boolean,
    icon_show_fail: Boolean,
    resultMessage: String,
    userId: String,
    paymentDepositId: String
  },
  components: { vUpload, Result },
  data() {
    return {
      fileList: []
    };
  },
  methods: {
    updateFileInfo(fileInfo) {
      this.fileList = fileInfo.fileList;
    },
    uploadImg(fileInfo) {
      if (this.fileList.length == 0) {
        var vm = this;
        this.$message({
          message: vm.$t('common.formValidation.file'),
          type: 'error'
        });
        return;
      }
      this.UploadCard();
    },
    UploadCard() {
      apiUpload_card({
        paymentDepositId: this.paymentDepositId,
        userId: this.userId,
        fileList: this.fileList
      })
        .then(resp => {
          if (resp.data.data) {
            this.$emit('update:hideUpload', true);
            this.$emit('update:icon_show_success', true);
            this.$emit('update:resultMessage', this.$t('deposit.cc.result.success'));
          }
        })
        .catch(err => {
          this.$message({
            message: 'Upload failed. please try later.',
            type: 'error'
          });
        });
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/css/components/template/creditCardTemplate.scss';
</style>
