<template>
  <div class="depositFunds_box">
    <div class="form_credit">
      <div class="box_card_top marginTop">
        <div class="info_box">
          <img src="@/assets/images/channel/mastercard.png" alt />
          <p>
            {{ $t('deposit.method.desc', { method: $t('deposit.creditOrDebit.header') }) }}
          </p>
          <ul>
            <li>{{ $t('deposit.method.inst1') }}</li>
            <li>{{ $t('deposit.method.inst5') }}</li>
            <li>{{ $t('deposit.method.inst6') }}</li>
          </ul>
        </div>
      </div>
      <div class="box_card_bottom">
        <div class="form_main">
          <el-form :model="form" ref="form" :rules="rules">
            <ul class="clearfix mb_10">
              <li class="fl">
                <AccountNumber
                  :supportedCurrenciesList="validCurrencies"
                  @setAccountNumber="setAccountNumber"
                  @setCurrency="setCurrency"
                ></AccountNumber>
              </li>
              <li class="fr">
                <numeric-input
                  v-model="form.amount"
                  :currency="form.currency"
                  :precision="2"
                  :label="$t('common.field.amt')"
                  name="amount"
                ></numeric-input>
              </li>
            </ul>

            <ul class="clearfix mb_10">
              <li class="fl">
                <SelectForm
                  v-model="card.selected"
                  :label="$t('deposit.cc.field.fundNow')"
                  name="card"
                  v-if="form.accountNumber && card.cardOptions.length > 0"
                >
                  <el-option
                    v-for="(item, index) in card.cardOptions"
                    :key="index"
                    :value="item.id"
                    :label="setCardLabel(item)"
                    :data-testid="item.id"
                  ></el-option>
                  <el-option :label="$t('deposit.cc.field.newCard')" :value="0" data-testid="newCard"></el-option>
                </SelectForm>
              </li>

              <li :class="[!card.cardOptions.length > 0 ? 'fl' : 'fr']">
                <InputForm
                  :label="$t('deposit.cc.field.nameOnCard')"
                  name="nameCard"
                  maxlength="32"
                  v-model="form.nameCard"
                  :disabled="card.selected != ''"
                >
                </InputForm>
              </li>
            </ul>

            <ul class="clearfix mb_10">
              <li class="fl" v-if="card.cardOptions.length == 0 || card.selected == '0'">
                <InputForm
                  :label="$t('deposit.cc.field.cardNum')"
                  name="solidpaySACreditCard"
                  v-model="form.solidpaySACreditCard"
                ></InputForm>
              </li>

              <div v-else>
                <div class="labelTitle">
                  <span class="required_icon">*</span>
                  <label for="" class="purple">{{ $t('deposit.cc.field.cardNum') }}</label>
                </div>

                <div class="ccRow">
                  <InputForm :disabled="true" v-model="form.oldCreditCardFirst" name="oldCreditCardFirst"></InputForm>
                  <InputForm v-model="form.oldCreditCardMiddle" maxlength="7" name="oldCreditCardMiddle"></InputForm>
                  <InputForm :disabled="true" v-model="form.oldCreditCardLast" name="oldCreditCardLast"></InputForm>
                </div>
              </div>
            </ul>

            <ul class="clearfix mb_10">
              <div class="labelTitle">
                <span class="required_icon">*</span>
                <label for="" class="purple"> {{ $t('deposit.cc.field.expDate') }}</label>
              </div>
              <li class="fl">
                <el-form-item prop="month" class="month">
                  <Month :year="form.year" v-model="form.month"></Month>
                </el-form-item>
              </li>
              <li class="fr">
                <el-form-item prop="year" class="year">
                  <Year v-model="form.year"></Year>
                </el-form-item>
              </li>
            </ul>

            <ul class="clearfix mb_10">
              <li class="fl">
                <InputForm
                  :label="$t('deposit.cc.field.cvv')"
                  name="securityCode"
                  maxlength="4"
                  v-model="form.securityCode"
                >
                </InputForm>
              </li>

              <li class="fr">
                <InputForm
                  :label="$t('common.field.imptNotes')"
                  name="applicationNotes"
                  v-model="form.notes"
                ></InputForm>
              </li>
            </ul>

            <div class="form_button">
              <el-button
                class="el-button purple_button"
                :loading="loading"
                :disabled="loading"
                @click="submitForm()"
                data-testid="submit"
              >
                {{ $t('common.button.submit') }}
              </el-button>
            </div>
          </el-form>
        </div>
      </div>
      <div class="desc_box">
        <ul>
          <li>{{ $t('transferFunds.pleaseNote') }}</li>
          <li>1. {{ $t('deposit.creditOrDebit.sameCard') }}</li>
          <li>
            <p
              v-html="
                '2. ' + $t('deposit.creditOrDebit.note', { mail: this.$config.info.getDepositEmail(this.regulator) })
              "
            ></p>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import NumericInput from '@/components/NumericInput';
import AccountNumber from '@/components/form/AccountNumber';
import AvailableCurrency from '@/components/form/AvailableCurrency';
import Month from '@/components/form/Month';
import Year from '@/components/form/Year';
import solidpay from '@/mixins/page/deposit/solidpay';

export default {
  name: 'Solidpayment',
  components: { NumericInput, AccountNumber, AvailableCurrency, Month, Year },
  mixins: [solidpay],
  props: { activeIndex: Number },
  data() {
    return {
      validCurrencies: ['USD', 'EUR', 'GBP']
    };
  }
};
</script>
<style lang="scss" scoped>
@import '@/assets/css/pages/deposit/creditOrDebit.scss';
@import '@/assets/css/pages/deposit/default.scss';

.box_card_top {
  box-shadow: unset;
  padding: 0 0 20px;
}
</style>
