import { apiFetch_card } from '@/resource'
import { ccIsVaild } from '@/util/cc-validate'
import { apiCc_solid } from '@/resource'
import Mixin from '@/mixins/page/deposit'

export default {
  mixins: [Mixin],
  data() {
    const re = /^[0-9\s]*$/
    const reg = /^\d{4}([ \-]?)(\d{4}\1?\d{4}\1?\d{4})+$/
    const regMaster = /^5.*/

    const onlyNumber = value => {
      value
        .replace(/[0-9]/g, '')
        .replace(/[\s]/g, '')
        .split('')
        .map(m => {
          value = value.replace(m, '')
        })
      return value
    }

    const validateAmount = (rule, value, callback) => {
      if (value === '' || !Number(value)) {
        callback(new Error(this.$t('common.formValidation.amtReq')))
        } else if (parseFloat(value) < this.minLimit) {
      // } else if (parseFloat(value) < this.tempMinLimit) {
        callback(new Error(this.$t('common.formValidation.amtLarger_currency_type', { currencyType: this.$options.filters.currencyToSymbol(this.accountCurrency), minLimit: this.minLimit, })))
        // callback(new Error(this.$t('common.formValidation.amtLarger_currency_type', { currencyType: this.$options.filters.currencyToSymbol(this.accountCurrency), minLimit: this.tempMinLimit, })))
      } else if (parseFloat(value) > this.maxLimit) {
        callback(
          new Error(
            this.$t('common.formValidation.amtLess_currency_type', { currencyType: this.$options.filters.currencyToSymbol(this.accountCurrency), maxLimit: this.maxLimit, currency: this.accountCurrency })
          )
        )
      } else {
        callback()
      }
    }

    const validCreditCard = (rule, value, callback) => {
      if (!re.test(value)) this.form.creditCard = onlyNumber(value)
      if (!reg.test(value)) {
        callback(new Error(this.$t('common.formValidation.digits16')))
      } else {
        this.form.creditCard = value.replace(/[^\d]/g, '').replace(/(\d{4})(?=\d)/g, '$1 ')
        callback()
      }
    }

    const validSolidpaySACreditCard = (rule, value, callback) => {
      if (!re.test(value)) this.form.solidpaySACreditCard = onlyNumber(value)
      const creditCardType = ccIsVaild(value)

      if(!regMaster.test(value) && this.activeIndex === 2){
        callback(new Error(this.$t('common.formValidation.digitsMaster')))
      }else if (!reg.test(value)) {
        callback(new Error(this.$t('common.formValidation.digits16')))
      } else {
        this.form.solidpaySACreditCard = value.replace(/[^\d]/g, '').replace(/(\d{4})(?=\d)/g, '$1 ')
        callback()
      }
    }

    const validCreditCardMiddle = (rule, value, callback) => {
      let reg
      if (rule.max == 6) reg = /^[0-9]{6}$/
      if (rule.max == 7) reg = /^[0-9]{7}$/
      if (!reg.test(value)) {
        callback(this.$t('common.formValidation.mid6Digits', { number: rule.max }))
      }
      callback()
    }

    const valiSecurityCode = (rule, value, callback) => {
      const reg = /^[0-9]{3,4}$/
      if (!reg.test(value)) {
        this.form.securityCode = value.toString().replace(/[^\d]/g, '')
        callback(new Error(this.$t('common.formValidation.digits3or4')))
      }
      callback()
    }

    return {
      tempMinLimit: this.activeIndex == 1 ? 15 : 1,
      maxLimit: 40000,
      form: {
        accountNumber: '',
        amount: '',
        nameCard: '',
        creditCard: '',
        solidpaySACreditCard: '',
        month: '',
        year: '',
        securityCode: '',
        is3DS: false,
        oldCreditCardFirst: '',
        oldCreditCardMiddle: '',
        oldCreditCardLast: '',
      },
      rules: {
        accountNumber: [
          {
            required: true,
            message: this.$t('common.formValidation.accReq'),
            trigger: 'change',
          },
        ],
        amount: [
          {
            required: true,
            validator: validateAmount,
            trigger: 'blur',
          },
        ],
        nameCard: [
          {
            required: true,
            message: this.$t('common.formValidation.cardOnNameReq'),
            trigger: 'blur',
          },
        ],
        creditCard: [
          {
            required: true,
            message: this.$t('common.formValidation.ccReq'),
            trigger: 'blur',
          },
          {
            validator: validCreditCard,
            trigger: 'blur',
          },
        ],
        solidpaySACreditCard: [
          {
            required: true,
            message: this.$t('common.formValidation.ccReq'),
            trigger: 'blur',
          },
          {
            validator: validSolidpaySACreditCard,
            trigger: 'blur',
          },
        ],
        month: [
          {
            required: true,
            message: this.$t('common.formValidation.expDateReq'),
            trigger: 'change',
          },
        ],
        year: [
          {
            required: true,
            message: this.$t('common.formValidation.expDateReq'),
            trigger: 'change',
          },
        ],
        securityCode: [
          {
            required: true,
            message: this.$t('common.formValidation.cvvReq'),
            trigger: 'blur',
          },
          {
            validator: valiSecurityCode,
            trigger: 'blur',
          },
        ],
        oldCreditCardMiddle: [
          {
            required: true,
            message: this.$t('common.formValidation.ccReq'),
            trigger: 'blur',
          },
          {
            validator: validCreditCardMiddle,
            max: 5,
            trigger: 'blur',
          },
        ],
      },
      card: { cardOptions: [], selected: '' },
    }
  },
  watch: {
    'form.accountNumber'(number) {
      if (number) this.fetchCard()
    },
    'card.selected'(cardNumber) {
      const _card = this.card.cardOptions.find(f => f.id === cardNumber)
      this.form.oldCreditCardMiddle = ''
      if (_card) {
        if (this.regexFourDigit(_card.card_last_four_digits)) {
          this.rules.oldCreditCardMiddle[1].max = 6
        } else {
          this.rules.oldCreditCardMiddle[1].max = 7
        }
        this.form.oldCreditCardFirst = _card.card_begin_six_digits
        this.form.oldCreditCardLast = _card.card_last_four_digits
        this.form.nameCard = _card.card_holder_name
        this.form.month = _card.expiry_month.toString()
        this.form.year = _card.expiry_year.toString()
        this.form.is3DS = _card.three_domain_secure == 1 ? true : false
      } else {
        this.form.oldCreditCardFirst = ''
        this.form.oldCreditCardLast = ''
        this.form.nameCard = ''
        this.form.month = ''
        this.form.year = ''
        this.form.is3DS = false
      }
      this.$refs['form'].validateField('oldCreditCardMiddle')
    },
    accountCurrency(value) {
      if (this.form.amount !== 0) this.$refs.form.validateField('amount');
    }
  },
  computed: {
    countryCode() {
      return this.$store.state.common.countryCode
    },

    leftright() {
      return form.accountNumber && card.cardOptions.length > 0 ? 'fr' : 'fl'
    },
  },
  methods: {
    setAccountNumber(accountNumber) {
      this.form.accountNumber = accountNumber
    },
    setCardLabel(card) {
      if (this.regexFourDigit(card.card_last_four_digits))
        return card.card_begin_six_digits + '-xxxxxxx-' + card.card_last_four_digits
      else return card.card_begin_six_digits + '-xxxxxx-' + card.card_last_four_digits
    },
    setApiHeader() {
      return {
        Timezone: new Date().getTimezoneOffset(),
        'Screen-Height': window.innerHeight,
        'Screen-Width': window.innerWidth,
        'Java-Enabled': navigator.javaEnabled(),
        'Screen-Color-Depth': window.screen.colorDepth,
        token: this.token
      }
    },
    regexFourDigit(number) {
      const reg = /^\d{4}$/
      return reg.test(number)
    },
    fetchCard() {
      apiFetch_card({ mt4Account: this.form.accountNumber }).then(resp => {
        if (resp.data.code == 0) {
          this.card = {
            cardOptions: resp.data.data.cards || [],
            selected: resp.data.data.cards[0]?.id || '',
          }
        }
      })
    },
    submitDeposit() {
      let card = this.form.solidpaySACreditCard
      if (this.form.solidpaySACreditCard == null || this.form.solidpaySACreditCard == '') {
        card = this.form.oldCreditCardFirst + this.form.oldCreditCardMiddle + this.form.oldCreditCardLast
      }
      card = card.replace(/[^\d]/g, '')
      let data = {
        mt4Account: this.form.accountNumber,
        paymentMethod: 'creditcard',
        depositAmount: this.form.amount,
        cardNumber: card,
        cardHolderName: this.form.nameCard,
        expireYear: this.form.year,
        expireMonth: this.$options.filters.appendzero(this.form.month),
        cvv: this.form.securityCode,
        brand: ccIsVaild(card),
        cardExist: this.form.oldCreditCardFirst ? true : false,
        loyaltyRewardId: this.voucherID,
      }
      if (this.activeIndex === 1) {
        data.type = 'virtualpay'
      }
      return apiCc_solid(
        data,
        this.setApiHeader()
      )
    },
    submitForm() {
      this.$refs['form'].validate(valid => {
        if (valid) {
          this.loading = true
          this.submitDeposit()
            .then(result => {
              if (result.data.code == 0 && result.data.data) {
                this.loading = false;
                window.location = result.data.data
              } else {
                this.errorMessage(this.$t('deposit.default.failed'))
                // re-calling anti-reuse token
                this.fetchToken()
                .then(resp => {
                  this.loading = false;
                })
                .catch(resp => {
                  this.loading = false;
                  this.$message({
                    message: this.$t('resetPassword.failed'),
                    type: 'error'
                  });
                });
              }
            })
            .catch(err => {
              this.loading = false
              this.$message({
                message: this.$t('deposit.default.failed'),
                type: 'error',
              })
            })
        }
      })
    },
  },
}
