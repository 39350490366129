<template>
  <CreditCardTemplate
    :formFlag="formFlag"
    :hideUpload.sync="hideUpload"
    :resultFlag="resultFlag"
    :successFlag="successFlag"
    :icon_show_success.sync="icon_show_success"
    :icon_show_fail="icon_show_fail"
    :resultMessage.sync="resultMessage"
    :userId="userId"
    :paymentDepositId.sync="paymentDepositId"
  >
    <div class="info_box_choose">
      <el-button
        v-for="(item, index) in filteredOptions"
        :key="item.name"
        :class="{ purple_button: activeIndex === item.value, white_button: activeIndex !== item.value }"
        @click="buttonChange(item.value)"
        :data-testid="item.name"
      >
        {{ sequence[index] }}
      </el-button>
      <!-- <p>{{ $t('deposit.cc.remind') }}</p> -->
    </div>
    <component
      :formFlag.sync="formFlag"
      :hideUpload.sync="hideUpload"
      :resultFlag.sync="resultFlag"
      :successFlag.sync="successFlag"
      :icon_show_success.sync="icon_show_success"
      :icon_show_fail.sync="icon_show_fail"
      :resultMessage.sync="resultMessage"
      :userId.sync="userId"
      :paymentDepositId.sync="paymentDepositId"
      :is="currentComponent"
      :activeIndex="activeIndex"
      ref="child"
    ></component
  ></CreditCardTemplate>
</template>

<script>
import creditCard from '@/mixins/creditCard';
import showCreditcard from '@/mixins/page/deposit/showCreditcard';

import Solidpayment from '@/components/deposit/creditOrDebit/Solidpayment';
import Virtualpaypayment from '@/components/deposit/creditOrDebit/Virtualpaypayment';

import CreditCardTemplate from '@/components/template/deposit/CreditCardTemplate';

export default {
  name: 'creditCard',
  mixins: [creditCard, showCreditcard],
  components: { Solidpayment, CreditCardTemplate },
  data() {
    const optionsData = [
      { name: ' Virtualpaypayment', value: 1, component: Virtualpaypayment },
      { name: 'Solidpayment', value: 2, component: Solidpayment }
    ];
    return {
      activeIndex: null,
      currentComponent: null,
      sequence: ['Primary', 'Secondary', 'Tertiary', 'Quaternary', 'Quinary'],
      options: optionsData,
      optionCount: 0
    };
  },
  methods: {
    buttonChange(value) {
      this.activeIndex = value;
    },
    countIncrement() {
      this.optionCount = this.optionCount + 1;
    }
  },
  computed: {
    filteredOptions: function() {
      const optionDisplay = value => {
        switch (value) {
          case 1:
            return this.showvirtualpay;
          case 2:
            return this.showSolidpay;
          default:
            return false;
        }
      };
      const optionsList = this.options.filter(item => optionDisplay(item.value));
      // this.activeIndex = optionsList[0].value;
      return optionsList;
    }
  },
  created() {
    this.activeIndex = this.filteredOptions[0].value;
  },
  watch: {
    activeIndex: {
      handler(val) {
        if (val) this.currentComponent = this.options.find(item => item.value === val).component;
      },
      immediate: true
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/css/components/deposit/multiButton.scss';

.purple_button {
  border-radius: 16px;
  border: 2px solid rgba(60, 67, 155, 0.1);
}
.white_button {
  border-radius: 16px;
  border: 2px solid #3c439b;
  background-color: #ffffff;
  color: $primary-purple;
}
.purple_button,
.white_button {
  width: 140px;
  &:focus {
    background-color: $primary-purple;
    color: #fff;
  }
  &:hover,
  &:active {
    border: 2px solid #3c439b;
    background-color: rgba(60, 67, 155, 0.15);
    color: $primary-purple;
  }
}
</style>
