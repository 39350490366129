export function ccIsVaild(cardNumber) {
  if (typeof cardNumber !== 'string') {
    return ''
  } else {
    cardNumber = cardNumber.replace(/\s/g, '')
    const intCardNumber = parseInt(cardNumber)
    if (intCardNumber === 0) return ''
  }

  const length = cardNumber.length

  const firstDigit = parseInt(cardNumber.charAt(0))
  const twoDigits = parseInt(cardNumber.substring(0, 2))

  if ((twoDigits === 34 || twoDigits === 37) && length === 15) {
    return 'amex'
  } else if (firstDigit === 4 && length >= 16 && length <= 19) {
    return 'visa'
  } else if ((twoDigits >= 51 && twoDigits <= 55) || (twoDigits >= 22 && twoDigits <= 27)) {
    if (length === 16) {
      return 'master'
    }
  } else return ''
}
