<template>
  <div class="container">
    <el-button
      v-for="item in displayCurrencies"
      :key="item.currency"
      class="purple_button"
      :data-testid="item.currency"
    >
      {{ item.value }}
    </el-button>
  </div>
</template>

<script>
export default {
  name: 'AvailableCurrency',
  props: {
    validCurrencies: Array
  },
  data() {
    const regulator = this.$store.state.common.regulator;
    return {
      displayCurrencies: [],
      chooseCurrency: this.$config.openLiveAccount.chooseCurrency(regulator)
    };
  },
  mounted() {
    this.filterCurrency(this.validCurrencies);
  },
  methods: {
    filterCurrency(currenciesList) {
      this.displayCurrencies = this.chooseCurrency.filter(function (item) {
        return currenciesList.find(el => el === item.currency);
      });
    }
  }
};
</script>
<style lang="scss" scoped>
.container {
  display: inline;
}
.purple_button {
  background-color: #ecf5ff;
  color: $primary-purple;
}
</style>
